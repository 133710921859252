import React from 'react';

function page() {
  return (
    <div>
      <div className='flex flex-col justify-center items-center'>
        <h1 className='text-[48px] font-bold text-stone-300'>404</h1>
        <h2 className='text-[24px] text-stone-500'>Page not found</h2>
      </div>
    </div>
  );
}

export default page;
